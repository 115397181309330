<div class="main-container" [ngClass]="{ 'no-padding': !showSidebar }">
  <app-navigation
    #navigation
    [navTop]="navTop"
    [navBottom]="navBottom"
    [asUser]="asUser"
    [viewType]="viewType"
    *ngIf="showSidebar"
  />
  <main class="position-relative">
    <router-outlet />
  </main>
</div>
