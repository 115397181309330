<div
  class="d-flex flex-column align-items-center justify-content-center vh-100 mx-5 relative"
>
  <img
    src="/assets/images/HMS-02.png"
    alt=""
    style="position: absolute; top: 10px; left: 10px; width: 200px"
  />
  <img
    src="/assets/images/img-child-lap.png"
    alt="Under Construction"
    style="height: 200px"
  />
  <div class="text-center w-75">
    <h1 class="text-secondary">Under Construction!</h1>
    <p class="">
      Santa's Elves are hard at work building something special just for you!
      Check back soon
    </p>
  </div>
</div>
