import { isArray as _isArray } from 'lodash';

export function formatSearch(search: string, searchCols: string[], filters: any = {}) {
  const condition: any = [];
  const cols = searchCols.reduce((prev, next) => {
    prev[next] = [];
    return prev;
  }, {});

  if (search) {
    search.trim().split(/"/g).map((p, i) => {
      if (i % 2 === 1) {
        searchCols.forEach(col => cols[col].push({ and: [col, 'like', p] }));
      } else {
        p.trim().split(/[,()\s]+/g).map(w => {
          if (w) {
            searchCols.forEach(col => cols[col].push({ and: [col, 'like', w] }));
          }
        });
      }
    });
  }

  if (cols[Object.keys(cols)[0]].length) {
    Object.keys(cols).forEach(col => {
      if (Object.keys(filters).length) {
        Object.keys(filters).forEach(key => {
          cols[col].push({ and: [key, _isArray(filters[key]) ? 'in' : '=', filters[key]] })
        });
      }
      condition.push({ or: cols[col] });
    });
  } else {
    Object.keys(filters).forEach(key => {
      condition.push({ and: [key, _isArray(filters[key]) ? 'in' : '=', filters[key]] })
    });
  }
  return condition;
}