import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@environment";

@Component({
  selector: "app-under-construction",
  templateUrl: "./under-construction.component.html",
  styleUrls: ["./under-construction.component.scss"],
})
export class UnderConstructionComponent {
  constructor(private router: Router) {
    if (!environment.maintenanceMode) this.router.navigate(["/"]);
  }
}
