import { Component, OnDestroy, ViewChild } from "@angular/core";
import { NavigationComponent } from "@shared/navigation/navigation.component";
import { Location } from "@models/location";
import { Subject } from "rxjs";
import { LocationService } from "@services/location.service";
import { takeUntil } from "rxjs/operators";
import { ACCESS } from "@enum/access";

@Component({
  selector: "app-admin-layout",
  templateUrl: "../main-layout/main-layout.component.html",
  styleUrls: ["../main-layout/main-layout.component.scss"],
})
export class AdminLayoutComponent implements OnDestroy {
  preLogout = () => {
    this.navigation?.signOut();
  };
  showSidebar: boolean = true;
  location: Location;
  asUser?: number;
  destroyed$ = new Subject();
  viewType: string = "admin";

  navTop = [
    // {
    //   name: "Dashboard",
    //   url: '/admin/dashboard',
    //   icon: "/assets/images/navigation/icon-dashboard.svg",
    //   access: ACCESS.CORPORATE_ADMIN,
    // },
    {
      name: "Checkin",
      url: "/admin/checkin",
      icon: "/assets/images/navigation/icon-checkin.svg",
      access: ACCESS.LOCATION_ADMIN,
    },
    {
      name: "Fulfillment",
      url: "/admin/fulfillment",
      icon: "/assets/images/navigation/icon-printer.svg",
      access: ACCESS.LOCATION_ADMIN,
    },
    {
      name: "Customers",
      url: "/admin/customers",
      icon: "/assets/images/navigation/icon-customers.svg",
      access: ACCESS.CORPORATE_ADMIN,
    },
    {
      name: "Locations",
      url: "/admin/locations",
      icon: "/assets/images/navigation/icon-locations.svg",
      access: ACCESS.CORPORATE_ADMIN,
    },
    {
      name: "Packages",
      url: `/admin/packages/${this.locationId}`,
      icon: "/assets/images/navigation/icon-packages.svg",
      access: ACCESS.CORPORATE_ADMIN,
    },
    {
      name: "Calendar",
      url: "/admin/calendar",
      icon: "/assets/images/input-icons/icon-calendar.svg",
      access: ACCESS.CORPORATE_ADMIN,
    },
    {
      name: "Codes",
      url: "/admin/codes",
      icon: "/assets/images/book-appointment/icon-gift-card.svg",
      access: ACCESS.CORPORATE_ADMIN,
    },
  ];
  navBottom = [
    {
      name: "Users",
      url: "/admin/users",
      access: ACCESS.SUPER_USER,
    },
    {
      name: "Site Settings",
      url: "/admin/site-settings",
      access: ACCESS.CORPORATE_ADMIN,
    },
    // {
    //   name: "Advertising",
    //   url: undefined,
    //   access: ACCESS.SUPER_USER,
    // },
    {
      name: "Log Out",
      fn: this.preLogout,
      access: ACCESS.LOCATION_ADMIN,
    },
  ];
  @ViewChild("navigation") public navigation: NavigationComponent;

  constructor(private locations: LocationService) {
    this.locations.coords$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((coords) => {
        this.locations
          .getClosest(coords)
          .subscribe((location) => (this.location = location));
      });
  }

  get locationId() {
    return this.location?.id || 1;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
