<ng-container *ngIf="cart.length > 0">
  <div class="row">
    <div class="col-12 col-lg-6 col-xl-8 order-2 order-lg-1">
      <!-- Address Details -->
      <div class="card shipping-address p-2 p-lg-4 mb-2">
        <div class="card-title">
          <h3>Shipping Details</h3>
        </div>
        <ng-container *ngIf="!showAddressForm">
          <p class="mb-0">
            {{ user.person?.first_name }} {{ user.person?.last_name }}
          </p>
          <p class="mb-0">{{ user.person?.address }},</p>
          <p>
            {{ user.person.city }}, {{ user.person.state }}
            {{ user.person.zip }}
          </p>
          <span
            class="edit-address small d-flex justify-content-start align-items-center"
            ><a (click)="editShippingAddress()">Edit Shipping Address</a></span
          >
        </ng-container>
        <ng-container *ngIf="showAddressForm">
          <form [formGroup]="addressForm">
            <app-input controlName="firstName" />

            <app-input controlName="lastName" />

            <div class="input-container mb-2">
              <input
                #search
                type="text"
                formControlName="address"
                placeholder="Address"
                class="auto-complete-input"
              />
            </div>

            <app-input controlName="city" />

            <app-input controlName="state" />

            <app-input controlName="zip" />

            <button
              class="btn btn-block btn-primary"
              (click)="updateUserPerson()"
            >
              <span *ngIf="loading !== 'updateUser'">Update</span>
              <div
                class="spinner-border spinner-border-sm text-white"
                role="status"
                *ngIf="loading === 'updateUser'"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>

            <div class="text-center w-100 my-2 cancel-address">
              <a
                *ngIf="
                  user.person.address && (user.person.city || user.person.zip)
                "
                (click)="initUserPerson()"
                >Cancel</a
              >
            </div>
          </form>
        </ng-container>
      </div>

      <!-- Payment Information -->
      <div
        class="card payment-information p-2 p-lg-4 mb-2"
        *ngIf="alaCartItems.length > 0 && !asUser"
      >
        <h3 class="card-title">Payment Information</h3>
        <div class="input-container">
          <div *ngIf="!last4" class="card-info form-control" #cardInfo></div>
          <div
            *ngIf="last4"
            (click)="resetCard()"
            class="card-info form-control"
          >
            &ensp;••••&ensp;••••&ensp;••••&ensp;{{
              paymentForm?.value?.stripe?.card?.last4 || ""
            }}
          </div>
        </div>

        <div class="error">
          <ng-container *ngIf="stripeError">
            Card number, expiration, and security code are required.
          </ng-container>
        </div>
      </div>

      <!-- Coupon Code -->
      <div
        class="card p-2 p-lg-4 mb-2"
        *ngIf="alaCartItems.length > 0 && !asUser"
      >
        <h3 class="card-title">Coupon Code</h3>
        <form *ngIf="paymentForm" [formGroup]="paymentForm">
          <button
            *ngIf="!editCode"
            (click)="editCode = true"
            type="button"
            class="btn coupon-code-btn"
          >
            <img
              src="/assets/images/book-appointment/icon-coupon-code.svg"
              alt="Coupon Code"
            />&nbsp;
            <ng-container
              *ngIf="!paymentForm?.value?.coupon_code; else couponCode"
              >Enter coupon code</ng-container
            >
            <ng-template #couponCode>
              Coupon code:
              <span class="coupon-code">{{
                paymentForm?.value?.coupon_code || ""
              }}</span>
            </ng-template>
          </button>
          <app-input
            *ngIf="editCode"
            (blur)="editCode = false"
            appFocus
            hideErrors
            controlName="coupon_code"
          ></app-input>
          <div>
            <ng-container *ngIf="paymentForm?.value?.coupon_code && !editCode">
              <img
                *ngIf="!coupon || paymentForm?.get('coupon_code')?.errors"
                src="/assets/images/warning.svg"
                alt="warning"
              />

              <span
                class="error"
                *ngIf="paymentForm?.get('coupon_code')?.hasError('codeLimit')"
              >
                Sorry, that coupon code has reached its maximum number of uses.
              </span>
              <span
                class="error"
                *ngIf="paymentForm?.get('coupon_code')?.hasError('codeExpired')"
              >
                Sorry, that coupon code has expired.
              </span>
              <span
                class="error"
                *ngIf="
                  paymentForm?.get('coupon_code')?.hasError('valueTooLarge')
                "
              >
                Sorry, the coupon cannot exceed the subtotal
              </span>
            </ng-container>
            <ng-container
              *ngIf="coupon && paymentForm?.value?.coupon_code && !editCode"
            >
              <span class="coupon-applied"> Coupon code applied! </span>
            </ng-container>
          </div>
        </form>
      </div>

      <!-- Gift Card -->
      <div
        class="card gift-card p-2 p-lg-4 mb-2"
        *ngIf="alaCartItems.length > 0 && !asUser"
      >
        <h3 class="card-title">Gift Card</h3>
        <form [formGroup]="giftCardForm">
          <app-input label="Gift Card Code" controlName="gift_card_code" />
          <button
            class="btn btn-primary btn-block"
            (click)="addGiftCard()"
            *ngIf="!!giftCardForm?.value?.gift_card_code"
            [disabled]="
              loading === 'giftCard' || !giftCardForm.value?.gift_card_code
            "
            [ngClass]="{ disabled: !giftCardForm.value?.gift_card_code }"
          >
            <span *ngIf="loading !== 'giftCard'">Apply Gift Card</span>
            <div
              class="spinner-border spinner-border-sm text-white"
              role="status"
              *ngIf="loading === 'giftCard'"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </button>

          <div>
            <ng-container *ngIf="giftCardForm?.value?.gift_card_code">
              <img
                *ngIf="giftCardForm?.get('gift_card_code')?.errors"
                src="/assets/images/warning.svg"
                alt="warning"
              />
              <span
                class="error"
                *ngIf="
                  giftCardForm?.get('gift_card_code')?.hasError('notFound')
                "
              >
                Sorry, that does not appear to be a valid gift card.
              </span>
              <span
                class="error"
                *ngIf="
                  giftCardForm?.get('gift_card_code')?.hasError('zeroBalance')
                "
              >
                Sorry, that gift card has a zero balance.
              </span>
              <span
                class="error"
                *ngIf="
                  giftCardForm?.get('coupon_code')?.hasError('codeExpired')
                "
              >
                Sorry, that gift card has expired.
              </span>
            </ng-container>
            <ng-container
              *ngIf="giftCard && giftCardForm?.value?.gift_card_code"
            >
              <span class="coupon-applied"> Gift card applied! </span>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
    <div class="col-12 col-lg-6 col-xl-4 order-1 order-lg-2">
      <div class="card summary p-2 p-lg-4 mb-2">
        <h3 class="card-title">Order Summary</h3>

        <div class="order-summary">
          <!-- package details -->
          <ng-container *ngIf="packageItems.length > 0">
            <div *ngFor="let package of packageItems; let i = index">
              <div class="d-flex justify-content-between align-items-center">
                <h4>{{ package.package?.name }}</h4>
                <p>Pre-Paid $0.00</p>
              </div>
              <div
                class="d-flex justify-content-around align-items-center flex-wrap"
              >
                <div
                  class="d-flex justify-content-start align-items-center mr-3 my-2"
                  *ngFor="let size of package.packagePhotoSize"
                >
                  <div class="count-circle">
                    <span class="small">{{ size.quantity }}</span>
                  </div>
                  <p class="ml-2 mb-0">
                    {{ displayPhotoSize(size.photo_size_id) }}
                  </p>
                </div>
              </div>
              <hr *ngIf="alaCartItems.length > 0" />
            </div>
          </ng-container>

          <!-- cart details -->
          <ng-container *ngIf="alaCartItems.length > 0">
            <h3 class="mb-2">Additional Prints</h3>
            <div
              class="d-flex justify-content-between align-items-center"
              *ngFor="let item of alaCartItems; let i = index"
            >
              <h4>{{ item.photoSize?.size }} ({{ item.quantity }})</h4>
              <p>{{ item.photoSize?.price * item.quantity | currency }}</p>
            </div>
          </ng-container>
          <ng-container *ngIf="alaCartItems.length > 0">
            <div
              *ngIf="coupon && coupon.value"
              class="d-flex justify-content-between align-items-center text-secondary"
            >
              <h4>Discount Applied: {{ coupon.name }}</h4>
              <p>-{{ couponAppliedAmount | currency }}</p>
            </div>

            <!-- payment details -->
            <div class="d-flex justify-content-between align-items-center">
              <h4>Shipping</h4>
              <p>{{ shipping | currency }}</p>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <h4>Sales Tax</h4>
              <p>{{ salesTax | currency }}</p>
            </div>

            <div
              *ngIf="giftCard && giftCard.value"
              class="d-flex justify-content-between align-items-center text-secondary"
            >
              <h4>Gift Card Applied</h4>
              <p>-{{ giftCardAppliedAmount | currency }}</p>
            </div>

            <ng-container *ngIf="giftCard && giftCard.value">
              <div class="d-flex justify-content-between align-items-center">
                <h4>Remaining Gift Card Balance</h4>
                <p>{{ giftCardBalance | currency }}</p>
              </div>
            </ng-container>

            <hr />
            <div class="d-flex justify-content-between align-items-center">
              <h4>Total Amount to be Paid</h4>
              <p>{{ total | currency }}</p>
            </div>
          </ng-container>
        </div>

        <div class="my-4 d-none d-lg-block" *ngIf="!asUser">
          <ng-container *ngIf="alaCartItems.length > 0">
            <button
              class="btn btn-primary btn-block"
              (click)="placeOrder()"
              [disabled]="
                (loading === 'payment' || paymentForm.invalid) && total > 0
              "
              [ngClass]="{ disabled: paymentForm.invalid && total > 0 }"
            >
              <span *ngIf="loading !== 'payment'"> Place Order</span>
              <div
                class="spinner-border spinner-border-sm text-white"
                role="status"
                *ngIf="loading === 'payment'"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </ng-container>

          <ng-container *ngIf="alaCartItems.length === 0">
            <button class="btn btn-primary btn-block" (click)="orderPackage()">
              <span *ngIf="loading !== 'payment'"> Place Order</span>
              <div
                class="spinner-border spinner-border-sm text-white"
                role="status"
                *ngIf="loading === 'payment'"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-12 order-3 d-lg-none">
      <div class="card summary p-2 p-lg-4 mb-2">
        <h3 class="card-title">Order Summary</h3>

        <div class="order-summary">
          <!-- package details -->
          <ng-container *ngIf="packageItems.length > 0">
            <div *ngFor="let package of packageItems; let i = index">
              <div class="d-flex justify-content-between align-items-center">
                <h4>{{ package.package?.name }}</h4>
                <p>Pre-Paid $0.00</p>
              </div>
              <div
                class="d-flex justify-content-around align-items-center flex-wrap"
              >
                <div
                  class="d-flex justify-content-start align-items-center mr-3 my-2"
                  *ngFor="let size of package.packagePhotoSize"
                >
                  <div class="count-circle">
                    <span class="small">{{ size.quantity }}</span>
                  </div>
                  <p class="ml-2 mb-0">
                    {{ displayPhotoSize(size.photo_size_id) }}
                  </p>
                </div>
              </div>
              <hr />
            </div>
          </ng-container>

          <!-- cart details -->
          <ng-container *ngIf="alaCartItems.length > 0">
            <div
              class="d-flex justify-content-between align-items-center"
              *ngFor="let item of alaCartItems; let i = index"
            >
              <h4>{{ item.photoSize?.size }} ({{ item.quantity }})</h4>
              <p>{{ item.photoSize?.price * item.quantity | currency }}</p>
            </div>
          </ng-container>
          <ng-container *ngIf="alaCartItems.length > 0">
            <div
              *ngIf="coupon && coupon.value"
              class="d-flex justify-content-between align-items-center text-secondary"
            >
              <h4>Discount Applied: {{ coupon.name }}</h4>
              <p>-{{ couponAppliedAmount | currency }}</p>
            </div>

            <!-- payment details -->
            <div class="d-flex justify-content-between align-items-center">
              <h4>Shipping</h4>
              <p>{{ shipping | currency }}</p>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <h4>Sales Tax</h4>
              <p>{{ shipping | currency }}</p>
            </div>

            <div
              *ngIf="giftCard && giftCard.value"
              class="d-flex justify-content-between align-items-center text-secondary"
            >
              <h4>Gift Card Applied</h4>
              <p>-{{ giftCardAppliedAmount | currency }}</p>
            </div>

            <ng-container *ngIf="giftCard && giftCard.value">
              <div class="d-flex justify-content-between align-items-center">
                <h4>Remaining Gift Card Balance</h4>
                <p>{{ giftCardBalance | currency }}</p>
              </div>
            </ng-container>

            <hr />
            <div class="d-flex justify-content-between align-items-center">
              <h4>Total</h4>
              <p>{{ total | currency }}</p>
            </div>
          </ng-container>
        </div>

        <div class="my-4" *ngIf="!asUser">
          <ng-container *ngIf="alaCartItems.length > 0">
            <button
              class="btn btn-primary btn-block"
              (click)="placeOrder()"
              [disabled]="
                (loading === 'payment' || paymentForm.invalid) && total > 0
              "
              [ngClass]="{ disabled: paymentForm.invalid && total > 0 }"
            >
              <span *ngIf="loading !== 'payment'"> Place Order</span>
              <div
                class="spinner-border spinner-border-sm text-white"
                role="status"
                *ngIf="loading === 'payment'"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </ng-container>

          <ng-container *ngIf="alaCartItems.length === 0">
            <button class="btn btn-primary btn-block" (click)="orderPackage()">
              <span *ngIf="loading !== 'payment'"> Place Order</span>
              <div
                class="spinner-border spinner-border-sm text-white"
                role="status"
                *ngIf="loading === 'payment'"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="cart.length === 0">
  <div
    class="d-flex justify-content-center align-items-center flex-column"
    style="min-height: 80vh"
  >
    <h2>No items in your shopping cart</h2>
    <p>Purchase more prints today!</p>
    <button class="btn btn-secondary" [routerLink]="asUserUrl + '/my-photos'">
      My Photos
    </button>
  </div>
</ng-container>
