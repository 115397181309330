import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateFn,
  CanActivateChildFn,
  Router
} from '@angular/router';

// import moment = require('moment');
import { UserService } from '../services/user.service';

function userIsLoggedIn(route: ActivatedRouteSnapshot, router: Router, userService: UserService) {
  if (!userService.getToken()) {
    router.navigate(['/login']);
    return false;
  } else {
    try {
      // Check if the token is expired
      const token = userService.getToken(),
        payload = token.split('.')[1],
        exp = JSON.parse(atob(payload)).exp * 1000;

      if (exp < new Date().getTime()) {
        router.navigate(['/login']);
        return false;
      } else {
        return true;
      }
    } catch (e) {
      return false;
    }
  }
}

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  router: Router = inject(Router),
  userService: UserService = inject(UserService),
) => {
  return userIsLoggedIn(route, router, userService);
};

export const AuthGuardChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => AuthGuard(route, state);
