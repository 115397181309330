import { Injectable } from "@angular/core";
import { HttpService } from "./api.service";
import { Order } from "@models/order";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Cart } from "@models/cart";
import { CartService } from "./cart.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(
    private http: HttpService,
    private cartService: CartService,
    private user: UserService
  ) {}

  /**
   * Update a Single Order
   * req: order:Order
   * res {}
   */
  updateOrder(order: Order): Observable<Order> {
    return this.http
      .put(`/order/id/${order.id}`, order)
      .pipe(map((res) => res.data as Order));
  }

  /**
   * Create a new Cart
   * Using observable for updated v2 syntax
   * */
  createCart(cart: Cart[]): Observable<Cart[]> {
    return this.http.post(`/cart/create`, { cart }).pipe(
      map((res) => {
        this.fetchItems();
        return res.data;
      })
    );
  }

  getCartAppointments(user_id: any): Observable<Cart[]> {
    return this.http
      .post(`/cart/appointments/${user_id}`, {})
      .pipe(map((res) => res.response));
  }

  updateCart(cart: Cart): Observable<Cart> {
    return this.http.post(`/cart/update-single/${cart.id}`, { cart }).pipe(
      map((res) => {
        this.fetchItems();
        return res.data;
      })
    );
  }

  removeCartItems(cartId: number): Observable<any> {
    return this.http.post(`/cart/remove-item/${cartId}`, { cartId }).pipe(
      map((res) => {
        this.fetchItems();
        return res.data;
      })
    );
  }

  fetchItems() {
    const userId = this.user.userId;
    this.cartService.fetchCartItems(userId);
  }

  /**
   * Actually order the ala carte photos.
   *
   * @param data
   * @returns {Promise<any>}
   */
  alaCarteOrder(data: any): Observable<any> {
    return this.http.post(`/appointmentalacarte/submit-order`, data).pipe(
      map((res) => {
        this.fetchItems();
        return res.data;
      })
    );
  }

  getApptOrder(appointment_id: number): Observable<Order[]> {
    const body = {
      get: ["*"],
      where: {
        appointment_id,
        originating_order: true,
      },
      join: [
        {
          table: "Cart",
          joinType: "left join",
          get: ["*"],
          as: "cart",
          where: { package_order: true },
          returnType: "object",
          distinct: "id",
          on: [
            {
              Order: "id",
              Cart: "order_id",
            },
          ],
        },
      ],
    };

    return this.http.post(`/order/get`, body).pipe(
      map((res) => {
        return res.data;
      })
    );
  }
}
