import { Component } from "@angular/core";

@Component({
  selector: "app-book-now",
  template: `<div class="book-now-circle" routerLink="/book">
    <div>Book<br />Now!</div>
  </div>`,
  // templateUrl: './book-now.component.html',
  styleUrls: ["./book-now.component.scss"],
})
export class BookNowComponent {}
