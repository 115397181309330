<div class="modal-header">
  <button (click)="hide()" type="button" class="btn btn-sm btn-light btn-cancel">
    <img
      src="/assets/images/book-appointment/icon-modal-close.svg"
      alt="Cancel"
    />
  </button>
</div>
<div class="modal-body">
  <h3 class="modal-title">
    Are you sure you want to cancel your appointment?
  </h3>
  <br />
  <ng-container>
    <div>
      Cancellation is effective immediately. Credit card processing fees can not be refunded.
    </div>
    <div *ngIf="isWithin48">
      <hr/>
      Reservation fee of 
        <span class="text-secondary"> 
          {{ reservationFee | centsToDollars | currency }} 
        </span> 
      is non refundable for cancellations within 48 hours of your appointment.
    </div>
    <div *ngIf="refundAmount <= 0">
      <hr/>
      There is no balance to refund to your credit card.
    </div>
    <div *ngIf="refundAmount > 0">
      <hr/>
      Your credit card will receive a refund of
      <strong>
        {{ refundAmount | centsToDollars | currency }}
      </strong>
    </div>
    <div *ngIf="giftCardAmount > 0">
      <hr/>
      A credit of
      <strong>
      {{ giftCardAmount | centsToDollars | currency }}
      </strong>
      will be applied to the gift card used.
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <div class="row w-100">
    <div class="col">
      <button #okBtn
        (click)="confirm()"
        class="btn btn-waiting w-100 mb-2"
        type="button"
      >
      Confirm Cancellation
      </button>
    </div>
    <div class="col">
      <button
        #cancelBtn
        (click)="hide()"
        class="btn btn-default w-100"
        type="button"
      >
      Keep Appointment
      </button>
    </div>
  </div>
</div>

