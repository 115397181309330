import { Component, Input, OnDestroy } from "@angular/core";
import { NavItem } from "@interface/nav-item";
import { User } from "@models/user";
import { UserService } from "@services/user.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnDestroy {
  open = false;
  transition?: string;
  accessLevel: number;
  @Input() asUser?: User;

  destroyed$ = new Subject();

  preLogout = () => {
    // this.show = false;
    this.signOut();
  };

  @Input() navTop: NavItem[] = [];
  @Input() navBottom: NavItem[] = [];
  @Input() viewType: string;

  constructor(private userService: UserService) {
    this.userService.user$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user) => (this.accessLevel = +user.role?.access));
  }

  /**
   * Sign a user out.
   */
  signOut() {
    this.toggleNav(false);
    this.userService.logout();
  }

  toggleNav(open: boolean) {
    this.transition = "transform .2s ease-in-out";
    setTimeout(() => {
      this.open = open;
      setTimeout(() => {
        this.transition = undefined;
      }, 200);
    }, 0);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
