import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { HttpService } from "./api.service";
import { GiftCard } from "@models/gift-card";
import { formatSearch } from "app/utils/format-search";
import { of } from "rxjs";
import { DateTime } from "luxon";
import { dollarsToCents } from "@shared/dollars-to-cents.pipe";

@Injectable({ providedIn: "root" })
export class GiftCardService {
  constructor(private http: HttpService) {}

  save(value: GiftCard & { count?: number }, batch: boolean = false) {
    if (!value?.id) {
      delete value.id;

      if (!batch) {
        value.type = 'Coupon Code';
        delete value.count;
      } else {
        value.type = 'Gift Card';
        value.balance = value.value;
        delete value.use_limit;
        delete value.discount_type;
      }
    }

    // Store any dollar values as cents
    if (value.discount_type === 'DOLLAR' || value.type === 'Gift Card') {
      value.value = dollarsToCents(value.value);
      
      if (value.type === 'Gift Card') {
        value.balance = value.value;
      }
    }

    return this.http.post("/giftcard/save", value).pipe(map((res) => res.data));
  }

  get(
    folderIDs: number[] = [],
    { search, active }: { search?: string; active?: string } = {},
    sort = {}
  ) {
    const filters: any = {};
    if (active !== undefined) filters.active = active === "true";
    const condition = formatSearch(search, ["name"], filters);

    if (folderIDs?.length) {
      const inCondition = { and: ["folder_id", "IN", folderIDs] };

      if (condition?.length && condition[0].or) {
        condition.forEach((cond) => cond.or.push(inCondition));
      } else {
        condition.push(inCondition);
      }
    }

    const query: any = {
      get: ["*"],
      sort,
      group: ["name"],
      where: { condition },
    };

    return this.http.post(`/giftcard/get`, query).pipe(map((res) => res.data));
  }

  getByCode(params: any) {
    const query: any = {
      get: ["*"],
      where: {
        ...params,
        active: true,
      },
    };

    return this.http
      .post(`/giftcard/get`, query)
      .pipe(map((res) => res.data?.[0]));
  }

  getCSV(params = {}) {
    return this.http.get(`/giftcard/csv`, params).pipe(
      map((res) => {
        if (res.data) {
          this.downloadFile(res.data);
        }
        return "Export complete";
      })
    );
  }

  downloadFile(data: string) {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.download = `codes ${DateTime.now().toFormat(
      "yyyy-MM-dd hh:mm"
    )}.csv`;
    anchor.href = url;
    anchor.click();
  }
}
