import { animate, style, transition, trigger } from "@angular/animations";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import { Photo } from "@models/photo";

@Component({
  selector: "app-full-size-photo",
  templateUrl: "./full-size-photo.component.html",
  styleUrls: ["./full-size-photo.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("300ms ease-in", style({ opacity: 1 })),
      ]),
      transition(":leave", [animate("300ms ease-out", style({ opacity: 0 }))]),
    ]),
    trigger("slideUpDown", [
      transition(":enter", [
        style({ transform: "translateY(100%)" }),
        animate("300ms ease-out", style({ transform: "translateY(0)" })),
      ]),
      transition(":leave", [
        animate("300ms ease-in", style({ transform: "translateY(100%)" })),
      ]),
    ]),
  ],
})
export class FullSizePhotoComponent {
  @Input() photo: Photo;
  @Output() exit = new EventEmitter<any>();

  exitFullScreen() {
    this.exit.emit();
  }

  @HostListener("document:keydown.escape", ["$event"])
  onEscapeKey() {
    this.exit.emit();
  }

  @HostListener("document:swipedown", ["$event"])
  onSwipeDown() {
    this.exit.emit();
  }
}
