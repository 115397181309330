<ng-container *ngIf="cart.length > 0">
  <div class="row">
    <div class="col-12 col-lg-8 col-xl-7">
      <!-- package orders -->
      <ng-container *ngFor="let item of packageItems; let i = index">
        <div class="card p-2 mb-2 package-card">
          <div
            class="flex justify-content-between align-items-center flex-column"
          >
            <div
              class="w-100 d-flex justify-content-between align-items-center pb-2"
            >
              <div class="package-img-container">
                <img
                  class="card-img pointer"
                  (click)="setFullSize(item.photo)"
                  [src]="displayThumbnail(item.photo)"
                  [alt]="item.photo.filename"
                />
              </div>
              <div class="p-2">
                <h2 class="small">{{ item.package?.name }}</h2>
                <p class="small">{{ item.photo?.filename.split(".")[0] }}</p>
                <div
                  class="d-none d-lg-flex justify-content-start align-items-center"
                >
                  <div
                    class="d-flex justify-content-start align-items-center mr-3"
                    *ngFor="let size of item.packagePhotoSize"
                  >
                    <div class="count-circle">
                      <span class="small">{{ size.quantity }}</span>
                    </div>
                    <p class="ml-2 mb-0">
                      {{ displayPhotoSize(size.photo_size_id) }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="">
                <span class="small">Pre-paid</span>
              </div>
            </div>

            <div
              class="w-100 d-lg-none d-flex justify-content-around align-items-center py-2"
            >
              <div
                class="d-flex justify-content-start align-items-center mr-3"
                *ngFor="let size of item.packagePhotoSize"
              >
                <div class="count-circle">
                  <span class="small">{{ size.quantity }}</span>
                </div>
                <p class="ml-2 mb-0">
                  {{ displayPhotoSize(size.photo_size_id) }}
                </p>
              </div>
            </div>
          </div>

          <div class="card-footer bg-white d-flex justify-content-center">
            <a (click)="changePackagePhoto(item)" class="change-photo-action">
              <span class="mr-2">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.2069 4.92497L11.4144 2.13184C11.3215 2.03896 11.2113 1.96528 11.0899 1.91501C10.9686 1.86474 10.8385 1.83887 10.7072 1.83887C10.5759 1.83887 10.4458 1.86474 10.3245 1.91501C10.2031 1.96528 10.0929 2.03896 10 2.13184L2.29313 9.83934C2.19987 9.93186 2.12593 10.042 2.0756 10.1634C2.02528 10.2847 1.99959 10.4148 2.00001 10.5462V13.3393C2.00001 13.6046 2.10536 13.8589 2.2929 14.0464C2.48043 14.234 2.73479 14.3393 3.00001 14.3393H5.79313C5.9245 14.3398 6.05464 14.3141 6.17599 14.2637C6.29735 14.2134 6.40748 14.1395 6.50001 14.0462L14.2069 6.33934C14.2998 6.24648 14.3734 6.13623 14.4237 6.01489C14.474 5.89355 14.4999 5.76349 14.4999 5.63215C14.4999 5.50081 14.474 5.37076 14.4237 5.24942C14.3734 5.12808 14.2998 5.01783 14.2069 4.92497ZM5.79313 13.3393H3.00001V10.5462L8.50001 5.04622L11.2931 7.83934L5.79313 13.3393ZM12 7.13184L9.20688 4.33934L10.7069 2.83934L13.5 5.63184L12 7.13184Z"
                    fill="#246531"
                  />
                </svg>
              </span>
              <span>Change Package Photo</span>
            </a>
          </div>
        </div>
      </ng-container>

      <!-- a la cart orders -->
      <ul class="cart-orders p-2">
        <ng-container *ngFor="let alaCart of alaCartItems; let i = index">
          <li class="d-flex justify-content-between align-items-center py-3">
            <div class="d-flex alc-img">
              <img
                (click)="setFullSize(alaCart.photo)"
                [src]="displayThumbnail(alaCart.photo)"
                [alt]="alaCart.photo?.filename"
                class="card-img pointer"
              />
            </div>
            <div class="alc-details d-flex justify-content-between">
              <div
                class="d-flex flex-column justify-content-between align-items-start"
              >
                <span class="">{{ alaCart.photoSize.size }}</span>
                <span>${{ alaCart.photoSize.price }}/ea</span>
                <span class="small">{{
                  alaCart.photo?.filename.split(".")[0]
                }}</span>
              </div>
              <div
                class="d-flex flex-justify-content-center align-items-center"
              >
                <span>{{
                  alaCart.photoSize.price * alaCart.quantity | currency
                }}</span>
              </div>
            </div>
            <div class="alc-inputs d-flex justify-content-end">
              <div class="count-input-grp">
                <button
                  class="minus-btn"
                  type="button"
                  [disabled]="as_user"
                  (click)="decreaseQuantity(i)"
                >
                  -
                </button>

                <input
                  type="text"
                  class="form-control text-center"
                  [value]="alaCart.quantity"
                  readonly
                />

                <button
                  class="plus-btn"
                  type="button"
                  [disabled]="as_user"
                  (click)="increaseQuantity(i)"
                >
                  +
                </button>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="col-12 col-lg-4 col-xl-5">
      <!-- Payment details -->
      <div class="card p-4 mb-4 payment-details position-relative">
        <h2 class="card-title d-none d-lg-block">Payment Details</h2>
        <div
          class="d-flex w-100 h-100 justify-content-center align-items-center loading-spinner"
          *ngIf="loading === 'cart-update'"
        >
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <!-- Payment Details -->
        <ul class="payment-list">
          <li
            class="d-flex justify-content-between align-items-center py-2 li-border-b"
          >
            <span class="bold">Subtotal</span>
            <span>{{ subTotal | currency }}</span>
          </li>

          <li
            class="d-flex justify-content-between align-items-center py-2 li-border-b"
          >
            <span class="bold">Sales Tax</span>
            <span>{{ salesTax | currency }}</span>
          </li>

          <li class="d-flex justify-content-between align-items-center py-2">
            <span class="bold">Total</span>
            <span>{{ total | currency }}</span>
          </li>
        </ul>

        <!-- Checkout Button -->
        <button class="btn btn-block btn-primary" (click)="goToCheckout()">
          <span *ngIf="loading !== 'checkout'"> Checkout</span>
          <div
            class="spinner-border spinner-border-sm text-white"
            role="status"
            *ngIf="loading === 'checkout'"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>

      <!-- Action Card -->
      <div class="card p-4">
        <h2 class="card-title">Want more prints?</h2>
        <button
          class="btn btn-block btn-secondary"
          (click)="routeTo('my-photos')"
        >
          Keep Shopping
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="cart.length === 0">
  <div
    class="d-flex justify-content-center flex-column align-items-center mvh-100"
    style="min-height: 80vh"
  >
    <h2>No items in your shopping cart</h2>
    <p>Purchase more prints today!</p>
    <button class="btn btn-secondary" [routerLink]="asUserUrl + '/my-photos'">
      My Photos
    </button>
  </div>
</ng-container>

<app-full-size-photo
  [photo]="fullSizePhoto"
  (exit)="minimize()"
></app-full-size-photo>
