import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { SocialLoginService } from "../services/social-login.service";
import { UserService } from "../services/user.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CapitalizePipe } from "../shared/capitalize.pipe";
import { FulfillmentService } from "../services/fulfillment.service";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { NotifyService } from "@services/notify.service";

@Component({
  selector: "app-create-account",
  templateUrl: "./create-account.component.html",
  styleUrls: ["./create-account.component.scss"],
})
export class CreateAccountComponent implements OnInit, AfterViewInit {
  @ViewChild("phoneFocus") vc: any;
  login: any = {};
  createAccountForm: FormGroup;
  alert: any;
  mode: string;
  socialUserFirstName: string;
  socialUserLastName: string;
  socialUserEmail: string;
  socialLogin: Boolean = false;
  userId: any;
  private sub: any;
  private socialSub: any;
  accountHeading: string;
  loading: Boolean = false;

  constructor(
    private loginService: SocialLoginService,
    private fb: FormBuilder,
    private capitalize: CapitalizePipe,
    private router: Router,
    private fulfill: FulfillmentService,
    private route: ActivatedRoute,
    private user: UserService,
    private notify: NotifyService
  ) {
    this.alert = null;

    /**
     * Social Login functions.
     *
     * @param {string} type
     */
    this.login.withStrategy = (type: string) => {
      loginService.findStrategy(type)();
    };

    /**
     * Our createAccountForm definition.
     *
     * @type {FormGroup}
     */
    const v = Validators,
      vc = Validators.compose.bind(Validators);

    this.createAccountForm = fb.group({
      firstName: ["", v.required],
      lastName: ["", v.required],
      emails: fb.group({
        email: ["", vc([v.required, v.email])],
      }),
      phone: ["", v.required],
      passwords: fb.group({
        password: ["", v.required],
      }),
    });
  }

  /**
   * Getter for our view to access the formGroup.
   *
   * @returns {AbstractControl}
   */
  get emailConfirm() {
    return this.createAccountForm.get("emails").get("emailConfirm");
  }

  /**
   * Get the email formcontrol.
   *
   * @returns {AbstractControl}
   */
  get email() {
    return this.createAccountForm.get("emails").get("email");
  }

  /**
   * Get the passwords.
   *
   * @returns {AbstractControl}
   */
  get passwordConfirm() {
    return this.createAccountForm.get("passwords").get("passwordConfirm");
  }

  /**
   * Get the password.
   *
   * @returns {AbstractControl}
   */
  get password() {
    return this.createAccountForm.get("passwords").get("passwordConfirm");
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.mode = params["mode"];
    });

    this.socialSub = this.route.queryParams.subscribe((params) => {
      // If the token is attached as a param, immediately request a new token in the body
      // from the server. -RJH
      if (params && params["token"]) {
        this.loginService.socialLogin(params["token"]).then((authRes) => {
          if (authRes.user.is_activated) {
            this.createAccountForm
              .get("passwords.password")
              .setValidators([Validators.required]);
            this.user.userAndToken = authRes;
            this.router.navigateByUrl("/home");
          }
        });
      }
    });

    if (this.loginService.socialDetails.firstName) {
      this.socialLogin = true;
      this.socialUserFirstName = this.loginService.socialDetails.firstName;
      this.socialUserLastName = this.loginService.socialDetails.lastName;
      this.socialUserEmail = this.loginService.socialDetails.email;
      this.userId = this.loginService.socialDetails.id;
      this.createAccountForm.patchValue({
        firstName: this.socialUserFirstName,
        lastName: this.socialUserLastName,
        emails: {
          email: this.socialUserEmail,
          emailConfirm: this.socialUserEmail,
        },
      });
    }

    this.accountHeading = this.socialLogin
      ? "Just one more thing..."
      : "Sign Up";
  }

  // After view loads, if the user doesn't have a person yet, autofocus on the
  // unfilled phone number field. -RJH
  ngAfterViewInit() {
    if (this.socialLogin && this.vc !== undefined) {
      this.vc.nativeElement.focus();
    }
  }

  /**
   * Create the users account.
   */
  createAccount(form: any) {
    this.loading = true;
    const phoneFormat = `+1${(form.phone || "").replace(/\D/g, "")}`;

    if (!this.socialLogin) {
      this.user
        .registerNewCustomer(
          form.firstName,
          form.lastName,
          form.emails.email.toLowerCase(),
          phoneFormat,
          form.passwords.password
        )
        .pipe(
          catchError((error: any) => {
            this.notify.toast({
              id: "create-account-failed-error",
              msg: error.error
                ? this.capitalize.transform(error.error.errorMessage)
                : "There was an issue! Please check your information and try again.",
              type: "warning",
            });
            this.loading = false;
            return throwError(error);
          })
        )
        .subscribe((authRes) => {
          this.user.userAndToken = authRes;
          this.loading = false;
          if (this.mode && this.mode.toLowerCase() === "kiosk") {
            this.router.navigateByUrl("/kiosk");
          } else {
            this.fulfill.setCart(this.user.userId).then(() => {
              this.router.navigateByUrl("/home");
            });
          }
        });
    } else {
      const userObj = { data: { id: this.userId } };
      this.user
        .createPerson(
          userObj,
          form.firstName,
          form.lastName,
          form.emails.email.toLowerCase(),
          phoneFormat,
          form.passwords.password
        )
        .subscribe((authRes) => {
          this.loading = false;
          if (!authRes) {
            this.notify.toast({
              id: "create-account-failed-error",
              msg: "There was an issue! Please check your information and try again.",
              type: "warning",
            });
          } else {
            this.user.userAndToken = authRes;
            if (this.mode && this.mode.toLowerCase() === "kiosk") {
              this.router.navigateByUrl("/kiosk");
            } else {
              this.fulfill.setCart(this.user.userId).then((res) => {
                this.router.navigateByUrl("/home");
              });
            }
          }
        });
    }
    this.socialUserFirstName = null;
    this.socialUserLastName = null;
    this.socialUserEmail = null;
    this.socialLogin = false;
    this.userId = null;
  }

  gotoLogin(event) {
    event.preventDefault();
    if (this.mode && this.mode.toLowerCase() === "kiosk") {
      this.router.navigateByUrl("/login/kiosk");
    } else {
      this.router.navigateByUrl("/login");
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
