import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { MainLayoutComponent } from "./layout/main-layout/main-layout.component";
import { SupportComponent } from "@shared/support/support.component";
import { AdminLayoutComponent } from "./layout/admin-layout/admin-layout.component";
import { AuthGuard } from "./auth/auth-guard";
import { RoleGuard, RoleGuardChild } from "app/services/role.guard";
import { ACCESS } from "@enum/access";
import { AsUserResolver } from "./resolvers/resolves/user.resolve";
import { UnderConstructionComponent } from "@shared/under-construction/under-construction.component";
import { MaintenanceGuard } from "./maintenance.guard";

export const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: "under-construction",
    component: UnderConstructionComponent,
  },
  {
    path: "",
    data: { title: "Holiday Magic" },
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: "admin",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, RoleGuard(ACCESS.LOCATION_ADMIN)],
    canActivateChild: [RoleGuardChild(ACCESS.LOCATION_ADMIN)],
    data: { title: "HMS Admin" },
    children: [
      {
        path: "customers",
        loadChildren: () =>
          import("./admin/users/users.module").then((m) => m.UsersModule),
        data: { title: "Customers" },
      },
      {
        path: "users",
        loadChildren: () =>
          import("./admin/users/users.module").then((m) => m.UsersModule),
        data: { title: "Users" },
      },
      {
        path: "checkin",
        loadChildren: () =>
          import("./admin/checkin/checkin.module").then((m) => m.CheckinModule),
        data: { title: "Checkin" },
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./admin/calendar/calendar.module").then(
            (m) => m.CalendarModule
          ),
        data: { title: "Calendar" },
      },
      {
        path: "codes",
        loadChildren: () =>
          import("./admin/codes/codes.module").then((m) => m.CodesModule),
        data: { title: "Codes" },
      },
      {
        path: "locations",
        loadChildren: () =>
          import("./admin/locations/locations.module").then(
            (m) => m.LocationsModule
          ),
        data: { title: "Locations" },
      },
      {
        path: "packages",
        loadChildren: () =>
          import("./admin/packages/packages.module").then(
            (m) => m.PackagesModule
          ),
        data: { title: "Packages" },
      },
      {
        path: "fulfillment",
        loadChildren: () =>
          import("./admin/fulfillment/fulfillment.module").then(
            (m) => m.FulfillmentModule
          ),
        data: { title: "Fulfillment" },
      },
      {
        path: "public/appt-booked",
        loadChildren: () =>
          import("./public/appt-booked/appt-booked.module").then(
            (m) => m.ApptBookedModule
          ),
        data: { title: "Appointment Booked" },
      },
      {
        path: "site-settings",
        loadChildren: () =>
          import("./admin/site-settings/site-settings.module").then(
            (m) => m.SiteSettingsModule
          ),
        data: { title: "Site Settings" },
      },
    ],
  },
  {
    path: "",
    component: MainLayoutComponent,
    data: { title: "Holiday Magic" },
    canActivate: [MaintenanceGuard],
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
        data: { title: "My Appointments" },
      },
      {
        path: "user-settings",
        loadChildren: () =>
          import("./user-settings/user-settings.module").then(
            (m) => m.UserSettingsModule
          ),
        data: { title: "My Account" },
      },
      {
        path: "my-photos",
        loadChildren: () =>
          import("./my-photos/my-photos.module").then((m) => m.MyPhotosModule),
        data: { title: "My Photos" },
      },
      // {
      //   path: "help",
      //   loadChildren: () =>
      //     import("./help/help.module").then((m) => m.HelpModule),
      //   data: { title: "Help" },
      // },
      // {
      //   path: "support",
      //   component: SupportComponent,
      //   data: { title: "Support" },
      // },
      {
        path: "payment",
        loadChildren: () =>
          import("./payment/payment.module").then((m) => m.PaymentModule),
        data: { title: "Payment" },
      },
      {
        path: "book",
        loadChildren: () =>
          import("./book-appointment/book-appointment.module").then(
            (m) => m.BookAppointmentModule
          ),
        data: { title: "" },
      },
    ],
  },
  {
    path: "as-user/:as_user",
    component: MainLayoutComponent,
    canActivate: [AuthGuard, RoleGuard(ACCESS.CORPORATE_ADMIN)],
    canActivateChild: [RoleGuardChild(ACCESS.CORPORATE_ADMIN)],
    data: { title: "HMS Admin As User" },
    resolve: {
      asUser: AsUserResolver,
    },
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
        data: { title: "User Home" },
      },
      {
        path: "user-settings",
        loadChildren: () =>
          import("./user-settings/user-settings.module").then(
            (m) => m.UserSettingsModule
          ),
        data: { title: "User Settings" },
      },
      {
        path: "my-photos",
        loadChildren: () =>
          import("./my-photos/my-photos.module").then((m) => m.MyPhotosModule),
        data: { title: "User Photos" },
      },
      // {
      //   path: "help",
      //   loadChildren: () =>
      //     import("./help/help.module").then((m) => m.HelpModule),
      //   data: { title: "User Help" },
      // },
      // {
      //   path: "support",
      //   component: SupportComponent,
      //   data: { title: "User Support" },
      // },
      {
        path: "book",
        loadChildren: () =>
          import("./book-appointment/book-appointment.module").then(
            (m) => m.BookAppointmentModule
          ),
        data: { title: "User Book" },
      },
      {
        path: "payment",
        loadChildren: () =>
          import("./payment/payment.module").then((m) => m.PaymentModule),
        data: { title: "Payment" },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: "always",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
