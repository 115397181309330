<div class="card mb-4 border-0 rounded-xl p-3 text-center">
  <!-- Support img -->
  <div class="img-container text-center">
    <img
      src="assets/images/contact/24-hour-guarantee-desktop.png"
      alt=""
      style="height: 150px"
    />
  </div>
  <h3 class="mb-2">Our 24-hour support guarantee</h3>
  <p class="">
    We’re here to make your holiday season a little less stressful! So we
    respond and resolve all phone calls and emails within 24 hours.
  </p>
</div>
<div class="text-center">
  <h4>Our customer care team is here to help</h4>
  <a href="tel:+15594254015" class="text-primary">(559) 425-4015</a>
</div>
