import { pick as _pick } from 'lodash';
import { Base } from './base';
import { Photo } from './photo';
import { Deliverable } from './deliverable';
import { PackagePhotoSize } from './package-photo-size';

export class Package extends Base {
  name: string;
  photos: Photo[] = [];
  price: number;
  description: string;
  photo?: string;
  photoSigned?: string;
  digital_only: boolean = false;
  taxable_value: number;
  tax_rate: number;
  deliverables: Deliverable[] = [];

  constructor(data?: any) {
    super(data);

    Object.assign(this, _pick(data, [
      'name',
      'price',
      'description',
      'photo',
      'photoSigned',
      'digital_only',
      'taxable_value',
      'tax_rate',
      'location_id'
    ]));

    const sizes = data?.['PhotoSize'] || [];

    this.photos = (data?.photos || []).filter(photo => photo).map(photo => {
      const size = sizes.find(size => size.id === photo.photo_size_id);
      photo.name = size?.size;
      return new PackagePhotoSize(photo);
    });

    // this.photos = (data?.photos || []).map(photo => new Photo(photo));
    this.deliverables = (data?.deliverables || []).filter(d => !!d).map(deliverable => new Deliverable(deliverable));
  }
}
