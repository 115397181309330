import { Pipe, PipeTransform } from '@angular/core';
import { NavItem } from '@interface/nav-item';
import { GiftCardFolder } from '@models/gift-card-folder';

@Pipe({ name: 'appMinNavHeight' })
export class MinNavHeightPipe implements PipeTransform {
  transform(access: number, navTop: NavItem[], navBottom: NavItem[]): any {
    const navTopCount = navTop.filter(nav => !nav.access || nav.access <= access).length;
    const navBottomCount = navBottom.filter(nav => !nav.access || nav.access <= access).length;
    return (navTopCount * 56) + (navBottomCount * 55) + 36;
  }
}
