import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phone",
})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Remove the country code if present and any non-numeric characters
    const cleaned = value.replace(/^\+1|\D/g, "");

    // Check if we have exactly 10 digits remaining
    if (cleaned.length === 10) {
      const areaCode = cleaned.slice(0, 3);
      const firstPart = cleaned.slice(3, 6);
      const secondPart = cleaned.slice(6);

      return `(${areaCode}) ${firstPart}-${secondPart}`;
    }

    // Return the original value if it does not match the expected pattern
    return value;
  }
}
