import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dollarsToCents',
  pure: false
})
export class DollarsToCents implements PipeTransform {
  transform(value: number): number {
   return dollarsToCents(value); 

  }
}

export function dollarsToCents(value: number): number {
  return Math.round(value * 100); // Convert dollars to cents
}