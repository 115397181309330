import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Appointment } from "@models/appointment";
import { Cart } from "@models/cart";
import { Photo } from "@models/photo";
import { FulfillmentService } from "@services/fulfillment.service";
import { NotifyService } from "@services/notify.service";
import { OrderService } from "@services/order.service";
import { Subject } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit {
  photoSizes: any[];
  cart: Cart[];
  session?: Appointment;
  packageItems: Cart[];
  alaCartItems: Cart[];
  salesTaxRate: number = 0.0785; // 7.85%
  salesTax: number;
  subTotal: number;
  total: number;
  loading: string = null;
  as_user: number | string;
  asUserUrl: string;
  fullSizePhoto: Photo = new Photo();

  private quantityChangeSubject: Subject<any> = new Subject<any>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private notifyService: NotifyService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.photoSizes = data["photoSizes"] || [];
      this.cart = data["cart"];
      this.session = data["session"];
      this.splitCarts();
    });

    this.route.params.subscribe((data) => {
      this.as_user = +data.as_user;
      this.asUserUrl = this.as_user ? `/as-user/${this.as_user}` : "";
    });

    this.quantityChangeSubject
      .pipe(
        debounceTime(500),
        switchMap((updatedCartItem) => {
          this.loading = "cart-update";
          if (updatedCartItem.quantity === 0) {
            return this.orderService.removeCartItems(updatedCartItem.id);
          } else {
            return this.orderService.updateCart(updatedCartItem);
          }
        })
      )
      .subscribe((updatedCart) => {
        if (updatedCart.cartId) {
          this.removeCartItem(+updatedCart.cartId);
        }
        this.showToast();
        this.calculateTotals();
        setTimeout(() => {
          this.loading = null;
        }, 500);
      });
  }

  showToast() {
    this.notifyService.toast({
      id: `updated-cart`,
      title: "Cart Updated",
      msg: `Your shopping cart has been updated!`,
      type: "success",
    });
  }

  splitCarts() {
    this.packageItems = this.cart.filter((c) => c.package_order);
    this.alaCartItems = this.cart.filter((c) => !c.package_order);
    this.calculateTotals();
  }

  setPhotoQuantity(photoSizeId: number) {
    let packageDetails = this.photoSizes.find(
      (p) => p.photo_size_id === +photoSizeId
    );
    return packageDetails.quantity;
  }

  displayPhotoSize(sizeId: number) {
    let size = this.photoSizes.find((s) => s.id === sizeId);
    return size.size;
  }

  changePackagePhoto(item: Cart) {
    this.router.navigate(
      [
        `${this.asUserUrl}/my-photos/santas-workshop/${item.appointment_id}/select-package-photo`,
      ],
      { queryParams: { photoId: item.photo_id } }
    );
  }
  increaseQuantity(index: number): void {
    this.alaCartItems[index].quantity += 1;
    const _cart = this.alaCartItems[index];

    this.quantityChangeSubject.next(_cart);
  }
  decreaseQuantity(index: number): void {
    if (this.alaCartItems[index].quantity > 0) {
      this.alaCartItems[index].quantity -= 1;

      const _cart = this.alaCartItems[index];

      this.quantityChangeSubject.next(_cart);
    }
  }

  removeCartItem(cartId: number) {
    this.cart = this.cart.filter((c) => c.id !== cartId);
    this.splitCarts();
  }

  displayThumbnail(photo: Photo) {
    const urlParts = photo.original.split(".");
    return `${urlParts[0]}.s3.us-west-2.amazonaws.com/${photo.appointment_id}/${photo.thumbnail}`;
  }

  /** Navigation functions ***/

  goToCheckout() {
    this.loading = "checkout";
    const url = this.route.snapshot.url;
    if (url[0].path == "order-details") {
      this.router.navigate([
        `${this.asUserUrl}/my-photos/santas-workshop/${this.session.id}/order-checkout`,
      ]);
    } else {
      this.router.navigate([`${this.asUserUrl}/payment/checkout`]);
    }
    this.loading = null;
  }

  routeTo(screen: string, cart?: Cart) {
    if (screen === "my-photos")
      this.router.navigate([`${this.asUserUrl}/my-photos`]);
    if (cart?.photo_id) {
      this.router.navigate([
        `${this.asUserUrl}/my-photos/santas-workshop/${cart.appointment_id}/${screen}?photoId=${cart.photo_id}`,
      ]);
    }
  }

  /*** price controls */

  calculateSubtotal(): number {
    return this.alaCartItems.reduce((subtotal, item) => {
      return subtotal + item.photoSize.price * item.quantity;
    }, 0);
  }

  calculateSalesTax(): number {
    return this.alaCartItems.reduce((taxAmount, item) => {
      return (
        taxAmount +
        item.photoSize.price * item.quantity * item["package"]["tax_rate"]
      );
    }, 0);
  }

  calculateTotal(): number {
    const subtotal = this.calculateSubtotal();
    const salesTax = this.calculateSalesTax();
    return subtotal + salesTax;
  }

  calculateTotals(): void {
    this.subTotal = this.calculateSubtotal();
    this.salesTax = this.calculateSalesTax();
    this.total = this.calculateTotal();
    // Optionally update UI with these values
  }

  setFullSize(photo: Photo) {
    this.fullSizePhoto = photo;
  }
  minimize() {
    this.fullSizePhoto = new Photo();
  }
}
