import { Injectable } from '@angular/core';
import { Coords } from '@models/coords';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpService } from "./api.service";
import { NotifyService } from './notify.service';
import { Location } from '@models/location';
import { environment } from '@environment';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class LocationService {
  public coords$ = new BehaviorSubject<Coords>(new Coords());

  private coordsID?: any;

  constructor(
    private notify: NotifyService,
    private http: HttpService
  ) {
    this.getLocation();
  }

  get(search?: string, page?: number, perPage?: number, sort?: string, active: boolean = null, csv = false) {
    const coords = this.coords$.getValue();
    const query: any = {};
    if (coords.lat) query.lat = coords.lat;
    if (coords.lng) query.lng = coords.lng;
    if (search) query.search = search;
    if (page) query.page = page;
    if (perPage) query.perPage = perPage;
    query.sort = sort || '.distance';
    if (active !== null) query.active = active;
    if (csv) query.csv = true;

    return this.http.get(`/location/all`, query)
      .pipe(switchMap(res => {
        if (res.csv) {
          this.downloadFile(res.csv);
        }
        res.data = (res.data || []).map(loc => new Location(loc));
        return of(res);
      }));
  }

  getActive(search?: string, page?: number, perPage?: number, sort?: string) {
    return this.get(search, page, perPage, sort, true);
  }

  getCSV(search?: string, page?: number, perPage?: number, sort?: string) {
    return this.get(search, page, perPage, sort, null, true);
  }

  save(saveLocation: Location) {
    saveLocation.phone = saveLocation.phone ? '+1' + saveLocation.phone.replace('+1', '').replace(/\D/g, '') : null;

    if (saveLocation.id) {
      return this.http.put(`/location/update/${saveLocation.id}`, saveLocation);
    } else {
      delete saveLocation.id;
      return this.http.post('/location/create', saveLocation);
    }
  }

  getOne(id: number) {
    return this.http.get(`/location/single/${id}`)
      .pipe(map(loc => new Location(loc)));
  }

  getClosest({ lat, lng }: { lat?: number, lng?: number }) {
    const distanceQuery = `ST_Distance(geocode, ST_SetSRID(ST_Point(${lng}, ${lat}), 4326)) / 1609.347087886444469 AS distance`;

    const query: any = {
      get: [
        '*',
        distanceQuery,
        'ST_X(geocode::geometry) as lng',
        'ST_Y(geocode::geometry) as lat',
        `concat(address, ' ', city, ', ', state, ' ', zip) as full_address`
      ],
      // sort: { distance: 'ASC' },
      limit: 1,
    };

    return this.http.post(`/location/get`, query)
      .pipe(map(res => new Location(res?.data?.[0])));
  }


  getLocation() {
    if (navigator.geolocation) {
      if (this.coordsID) {
        navigator.geolocation.clearWatch(this.coordsID);
      }
      // begin watching for location changes
      this.coordsID = navigator.geolocation.watchPosition(
        this.setPosition,
        this.error,
        { enableHighAccuracy: false, timeout: 60000, maximumAge: 60000 }
      );

    } else {
      this.notify.toast({ msg: 'Location services not available on this device.', timeout: 0 });
    }
  }

  /**
 * Get signed url for uploading image
 * req: { name: string }
 * res {}
 */

  getUploadURL(name: string) {
    return this.http.get('/location/photo-upload-url', { name });
  }

  /**
 * Sign in to Google Calendar
 * req: googleUser
 * res {}
 */
  calendarSignOn(googleUser: any, locationId: number): Observable<any> {
    return this.http.post(`/location/g-calendar/${locationId}`, googleUser);
  }

  /**
* Remove google calendar
* res {}
*/
  unlink(locationId: number) {
    return this.http.post(`/location/remove-g-calendar/${locationId}`, {});
  }

  setPosition = (position: any) => {
    const { latitude: lat, longitude: lng } = position.coords;
    this.notify.cancel('position-error');


    const coords: Coords = {
      lat,
      lng
    };
    this.coords$.next(coords);
  }

  error = (err: { message: string, code: number }) => {
    let msg = err.message;

    switch (err.code) {
      case 2:
        msg = 'Location services require HTTPS connection.';
        this.notify.toast({ msg, timeout: 0, id: 'position-error' });
        break;
      case 3:
        break;
      default:
        break;
    }
    this.coords$.next({ lat: undefined, lng: undefined });
  }

  distance(lon1: number, lat1: number, lon2: number, lat2: number, unit: 'M' | 'K' | 'N' = 'M') {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') { dist = dist * 1.609344; }
    if (unit === 'N') { dist = dist * 0.8684; }
    return dist;
  }

  reset() {
    this.getLocation();
  }

  getCoords(address: string) {
    return this.http.googleGet('/maps/api/geocode/json', { params: { address } })
      .pipe(
        map(data => {
          return data?.results || [];
        })
      );
  }

  downloadFile(data: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.download = `locations ${DateTime.now().toFormat('yyyy-MM-dd hh:mm')}.csv`;
    anchor.href = url;
    anchor.click();
  }
}
