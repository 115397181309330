import { Injectable } from '@angular/core';
import { HttpService } from './api.service';
import { map } from 'rxjs/operators';
import { App } from '@models/app';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private http: HttpService) { }

  /**
   * Get current app
   * req: {}
   * res {
   *  id: number
   *  app_name: string
   *  survey_link: string
   *  advertising_link: string
   * }
   */
  get() {
    return this.http.post('/app/get-app', {})
      .pipe(map(res => res.data));
  }

  save(app: Partial<App>) {
    return this.http.put(`/app/id/${app.id}`, app);
  }
}
