import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "@environment";
import { Cart } from "@models/cart";
import { GiftCard } from "@models/gift-card";
import { GiftCardService } from "@services/gift-card.service";
import { NotifyService } from "@services/notify.service";
import { OrderService } from "@services/order.service";
import { UserService } from "@services/user.service";
import { Subject } from "rxjs";
import { debounceTime, finalize, switchMap, takeUntil } from "rxjs/operators";
import { MapsAPILoader } from "@agm/core";
import { User } from "@models/user";
import { Person } from "@models/person";
import { CartService } from "@services/cart.service";
import { centsToDollars } from "@shared/cents-to-dollars.pipe";
import { dollarsToCents } from "@shared/dollars-to-cents.pipe";

declare let Stripe: any;

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit, OnDestroy {
  // Forms
  addressForm: FormGroup;
  paymentForm: FormGroup;
  giftCardForm: FormGroup;
  user: User;

  // Cart Items
  cart: Cart[];
  packageItems: Cart[];
  alaCartItems: Cart[];
  photoSizes: any[];

  // Price Items
  salesTaxRate: number = 0.0785; // 7.85%
  salesTax: number;
  subTotal: number;
  total: number;
  shipping: number = 7.0;

  // Payment info
  last4: number = null;
  private stripeObj;
  private stripeElements;
  private card: any;
  stripeError = false;
  editCode = false;
  giftCard: GiftCard;
  showAddressForm: boolean = true;
  giftCardBalance: number = 0;
  couponAppliedAmount: number;
  giftCardAppliedAmount: number;
  couponDiscountAmount: number = 0;
  couponDiscountPercentage: number = 0;
  couponDiscount: number = 0;
  coupon: GiftCard;
  asUser: number | string;

  loading: string = null;

  @ViewChild("cardInfo") cardInfo: ElementRef;
  @ViewChild("search") searchElementRef: ElementRef;

  destroyed$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private route: ActivatedRoute,
    private giftCardService: GiftCardService,
    private orderService: OrderService,
    private cartService: CartService,
    private notify: NotifyService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.cart = data["cart"] || [];
      this.photoSizes = data["photoSizes"] || [];
      this.user = data["user"];
      this.splitCarts();
    });
    this.asUser = this.route.snapshot.params?.as_user;

    this.initUserPerson();

    this.paymentForm = this.fb.group({
      stripe: [null, Validators.required],
      coupon_code: [null],
    });
    this.subscribeToCouponCode();

    this.giftCardForm = this.fb.group({
      gift_card_code: [null],
    });

    // this.loadStripe();
  }

  ngAfterViewInit() {
    this.loadStripe();
    this.paymentForm?.get("coupon_code").valueChanges.subscribe((value) => {
      // this.formatCouponCode(value);
      if (value === "") {
        this.resetCoupon();
        this.calculateTotals();
      } else {
        this.paymentForm
          ?.get("coupon_code")
          ?.setValue(value.toUpperCase(), { emitEvent: false });
      }
    });

    this.giftCardForm?.get("gift_card_code").valueChanges.subscribe((value) => {
      if (value === "") {
        this.resetGiftCard();
        this.calculateTotals();
      } else {
        this.formatGiftCard(value);
      }
    });

    this.findAddress();
  }

  editShippingAddress() {
    this.showAddressForm = true;
    // this.findAddress();
    setTimeout(() => {
      this.findAddress();
    }, 300);
  }

  resetGiftCard() {
    this.giftCard = null;
    this.giftCardBalance = 0;
    this.giftCardAppliedAmount = 0;
  }

  resetCoupon() {
    this.coupon = null;
    this.couponAppliedAmount = 0;
  }

  cancelEditAddress() {
    this.showAddressForm = false;
  }

  initUserPerson() {
    // Check if user.person exists
    if (this.user && this.user.person) {
      const { first_name, last_name, address, city, state, zip } =
        this.user.person;

      // Check if all required fields exist
      if (first_name && last_name && address && city && state && zip) {
        // All fields exist, hide address form
        this.showAddressForm = false;

        // Initialize the form with user person data
        this.addressForm = this.fb.group({
          firstName: [first_name, Validators.required],
          lastName: [last_name, Validators.required],
          fullAddress: [`${address}, ${city}, ${state} ${zip}`],
          address: [address],
          city: [city],
          state: [state],
          zip: [zip],
        });
      } else {
        // Some fields are missing, show address form
        this.showAddressForm = true;

        // Initialize the form with null values for the missing fields
        this.addressForm = this.fb.group({
          firstName: [first_name || null, Validators.required],
          lastName: [last_name || null, Validators.required],
          address: [address || null],
          fullAddress: [null],
          city: [city || null],
          state: [state || null],
          zip: [zip || null],
        });
      }
    } else {
      // No user.person, set the form with null values and show the address form
      this.showAddressForm = true;
      this.addressForm = this.fb.group({
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        address: [null],
        fullAddress: [null],
        city: [null],
        state: [null],
        zip: [null],
      });
    }
  }

  findAddress() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.searchElementRef.nativeElement.value =
            place.formatted_address || "";

          this.addressForm.patchValue({
            fullAddress: place.formatted_address || null,
          });

          if (place.address_components) {
            this.extractAddressComponents(place.address_components);
          }
        });
      });
    });
  }

  updateUserPerson() {
    this.loading = "updateUser";
    const { firstName, lastName, address, city, state, zip } =
      this.addressForm.value;
    const userPerson = this.user.person;

    Object.assign(userPerson, {
      first_name: firstName,
      last_name: lastName,
      address,
      city,
      state,
      zip,
    });
    return this.userService.updatePerson(userPerson).then((res: Person) => {
      this.user.person = res;

      this.notify.toast({
        id: "person-update",
        title: "Shipping Information Updated",
        msg: "Your shipping information has been updated",
        type: "success",
      });

      setTimeout(() => {
        this.loading = null;
        this.showAddressForm = false;
      }, 300);
    });
  }

  extractAddressComponents(
    addressComponents: google.maps.GeocoderAddressComponent[]
  ) {
    let streetNumber = "";
    let route = "";

    for (const component of addressComponents) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": // Street Number
          streetNumber = component.long_name;
          break;
        case "route": // Street Name
          route = component.long_name;
          break;
        case "locality": // City
          this.addressForm.patchValue({ city: component.long_name });

          break;
        case "administrative_area_level_1": // State
          this.addressForm.patchValue({ state: component.short_name });

          break;
        case "postal_code": // ZIP Code
          this.addressForm.patchValue({ zip: component.long_name });

          break;
      }
    }

    // Concatenate street number and route to form the street address
    this.addressForm.patchValue({ address: `${streetNumber} ${route}`.trim() });
  }

  splitCarts() {
    this.packageItems = this.cart.filter((c) => c.package_order);
    this.alaCartItems = this.cart.filter((c) => !c.package_order);
    this.calculateTotals();
  }

  displayPhotoSize(sizeId: number) {
    let size = this.photoSizes.find((s) => s.id === sizeId);
    return size.size;
  }

  /** Calculation Functions */
  /** Calculation Functions */
  calculateSubtotal(): number {
    return this.alaCartItems.reduce((subtotal, item) => {
      return subtotal + item.photoSize.price * item.quantity;
    }, 0);
  }

  calculateCouponDiscount(subtotal: number): number {
    let discount = 0;

    // Apply either a fixed dollar amount or percentage off, but not both.
    if (this.coupon && this.coupon.discount_type === "PERCENT") {
      const percent = this.coupon.value / 100;
      this.couponAppliedAmount = subtotal * percent;
      discount = subtotal * percent;
    } else if (this.coupon && this.coupon.discount_type === "DOLLAR") {
      discount = centsToDollars(this.coupon.value);
      this.couponAppliedAmount = discount;
    }

    const appliedDiscount = Math.min(discount, subtotal);
    const roundedDiscount = parseFloat(appliedDiscount.toFixed(2));

    return roundedDiscount;
  }

  calculateSalesTax(subtotal: number): number {
    let taxAmount = 0;

    if (this.coupon && this.coupon.discount_type === "PERCENT") {
      const percent = this.coupon.value / 100;

      return this.alaCartItems.reduce((taxAmount, item) => {
        const discountedPrice = item.photoSize.price * (1 - percent);
        const itemTotal = discountedPrice * item.quantity;
        return taxAmount + itemTotal * item["package"]["tax_rate"];
      }, 0);
    } else
      return this.alaCartItems.reduce((taxAmount, item) => {
        return (
          taxAmount +
          item.photoSize.price * item.quantity * item["package"]["tax_rate"]
        );
      }, 0);
  }

  calculateTotalWithoutGiftCard(): number {
    const subtotal = this.calculateSubtotal();
    const couponDiscount = this.calculateCouponDiscount(subtotal);
    const adjustedSubtotal = subtotal - couponDiscount;
    const salesTax = this.calculateSalesTax(adjustedSubtotal);

    return adjustedSubtotal + salesTax + this.shipping;
  }

  applyGiftCard(): void {
    const totalWithoutGiftCard = this.calculateTotalWithoutGiftCard();
    const balanceInDollars = centsToDollars(this.giftCard.balance);

    // If the gift card covers the total, calculate the remaining balance on the gift card
    if (this.giftCard && balanceInDollars >= totalWithoutGiftCard) {
      this.giftCardBalance = balanceInDollars - totalWithoutGiftCard;
      this.giftCardAppliedAmount = totalWithoutGiftCard;
    } else {
      this.giftCardBalance = 0;
      this.giftCardAppliedAmount = balanceInDollars;
    }

    // Recalculate totals after applying the gift card
    this.calculateTotals();
  }

  calculateTotals(): void {
    // subtotal should check
    const subtotal = this.calculateSubtotal();
    const couponDiscount = this.calculateCouponDiscount(subtotal);
    const salesTax = this.calculateSalesTax(subtotal - couponDiscount);

    this.subTotal = subtotal;
    this.couponDiscount = couponDiscount;
    this.salesTax = salesTax;
    this.total = this.calculateFinalTotal();
  }

  calculateFinalTotal(): number {
    const totalWithoutGiftCard = this.calculateTotalWithoutGiftCard();

    if (!this.giftCard) return totalWithoutGiftCard;

    // Apply the gift card after sales tax and shipping
    if (this.giftCardBalance > 0) return 0;

    const totalAfterGiftCard =
      totalWithoutGiftCard -
      Math.min(this.giftCardAppliedAmount, totalWithoutGiftCard);

    return Math.max(0, totalAfterGiftCard);
  }

  /*** Stripe functions*/
  loadStripe = () => {
    const loaded = !!document.querySelector(
      'script[src="https://js.stripe.com/v3/"]'
    );
    if (loaded) {
      this.stripeObj = Stripe(environment.stripePublicKey);
      this.stripeElements = this.stripeObj.elements();
      this.card = this.stripeElements.create("card", { disableLink: true });
      if (this.cardInfo) {
        this.card.mount(this.cardInfo.nativeElement);
        this.card.on("change", this.cardHandler);
        this.card.on("blur", this.onBlur);
      }
    }
  };

  resetCard() {
    this.last4 = null;
    this.paymentForm.patchValue({ stripe: null });
    setTimeout(() => this.loadStripe(), 0);
  }

  cardHandler = () => {
    setTimeout(() => {
      const complete = this.card?._complete;
      this.stripeError = false;

      if (complete) {
        this.setCard();
      }
    }, 0);
  };

  setCard = () => {
    const complete = this.card?._complete;
    this.stripeError = !complete;

    if (complete) {
      this.stripeObj.createToken(this.card).then(async ({ token, error }) => {
        if (error) {
          console.error("stripe error", error);
          return this.notify.toast({
            id: "stripe-error-information",
            title: "Stripe Error ",
            msg:
              error.error?.message ||
              "An issue occurred when attempting to initialize your card. Please check the info and try again",
            type: "danger",
          });
        } else {
          this.paymentForm.patchValue({ stripe: token });
        }
      });
    } else {
      this.paymentForm.patchValue({ stripe: null });
    }
  };

  onBlur = () => {
    const complete = this.card?._complete;
    this.stripeError = !complete;
  };

  /******  Code Functions ****/

  // Format the Coupon Code Input Value
  formatGiftCard(value: string) {
    const cleanValue = this.cleanValue(value);
    const formattedValue = cleanValue
      .replace(/(.{4})/g, "$1 ")
      .toUpperCase()
      .trim();

    this.giftCardForm
      ?.get("gift_card_code")
      .setValue(formattedValue, { emitEvent: false });
  }

  addGiftCard() {
    const giftCardCode = this.giftCardForm?.get("gift_card_code")?.value;
    if (!giftCardCode) {
      this.giftCard = null;
      this.resetGiftCard();
      return;
    }

    // Set loading state
    this.loading = "giftCard";

    // Clean the input value (optional, if needed)
    const cleanedGiftCardCode = this.cleanValue(giftCardCode);
    const query = {
      redeem_code: cleanedGiftCardCode,
      type: "Gift Card",
    };

    this.giftCardService
      .getByCode(query)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            this.loading = null;
          }, 300);
        })
      )
      .subscribe(
        (giftCard) => {
          const control = this.giftCardForm?.get("gift_card_code");
          let errors = {};

          if (giftCard) {
            // Check balance
            if (giftCard.balance <= 0) {
              errors = { ...errors, zeroBalance: true };
            }

            // Check if expiration_date is valid and compare
            if (
              giftCard.expiration_date &&
              new Date(giftCard.expiration_date) < new Date()
            ) {
              errors = { ...errors, codeExpired: true };
            }

            // If there are any errors, set them on the control
            if (Object.keys(errors).length > 0) {
              this.giftCard = null;
              control?.setErrors(errors);
            } else {
              // Clear errors if no validation errors
              this.giftCard = giftCard;
              control?.setErrors(null);
              this.applyGiftCard();
            }
          } else {
            this.giftCard = null;
            errors = { ...errors, notFound: true };
          }

          // this.calculateTotalCharge();
        },
        (error) => {
          console.error("Error fetching gift card:", error);
          // this.calculateTotalCharge();
        }
      );
  }

  subscribeToCouponCode() {
    this.paymentForm
      ?.get("coupon_code")
      .valueChanges.pipe(
        debounceTime(600),
        switchMap((code) => {
          const query = {
            redeem_code: code,
            type: "Coupon Code",
          };

          return this.giftCardService.getByCode(query);
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: (coupon) => {
          const control = this.paymentForm?.get("coupon_code");
          let errors = {};
          if (!coupon) {
            this.coupon = null;
            this.resetCard();
            return; // Stop further processing if no coupon code is found
          }

          const couponValue = centsToDollars(coupon.value);

          // Check if use_count and use_limit are not null before validating the usage limit
          if (coupon.use_limit !== null && coupon.use_count !== null) {
            if (coupon.use_count >= coupon.use_limit) {
              errors = { ...errors, codeLimit: true };
            }
          }

          // Check if expiration_date is valid and compare
          if (
            coupon.expiration_date &&
            new Date(coupon.expiration_date) < new Date()
          ) {
            errors = { ...errors, codeExpired: true };
          }

          // Check if coupon is dollar amount and if more than subtotal
          let adjustedSubTotal = this.total - this.shipping - this.salesTax;
          if (
            coupon.discount_type === "DOLLAR" &&
            couponValue >= adjustedSubTotal
          ) {
            errors = { ...errors, valueTooLarge: true };
          }

          // If there are any errors, set them on the control
          if (Object.keys(errors).length > 0) {
            this.coupon = null;
            control?.setErrors(errors);
          } else {
            // Clear errors if no validation errors
            this.coupon = coupon;
            control?.setErrors(null);
            this.calculateTotals();
          }
        },
        error: (err) => {
          console.error("Error fetching coupon code", err.message);
          this.calculateTotals();
        },
      });
  }

  cleanValue(value: string) {
    // Remove spaces
    return value.replace(/\s+/g, "");
  }

  placeOrder() {
    if (
      !this.user.person.address ||
      (!this.user.person.city && !this.user.person.zip)
    ) {
      return this.notify.toast({
        id: "invalid form information",
        title: "Address-Required ",
        msg: "A shipping address is required. Please update your shipping address to include, address, city, state and zip code.",
        type: "danger",
      });
    }
    let data = {
      giftCard: this.giftCard,
      giftCardAppliedAmount: dollarsToCents(this.giftCardAppliedAmount),
      coupon: this.coupon,
      couponAppliedAmount: this.couponAppliedAmount,
      amount: +this.total,
      token: this.paymentForm.value?.stripe?.id,
      userId: this.user.id,
      cart: this.cart,
      person: this.user.person,
    };

    // if gift card exists and value is 0 then bypass stripe check
    if (this.giftCard && this.total === 0) {
      this.finalizeOrder(data);
    } else if (!data.token || this.stripeError) {
      return this.notify.toast({
        id: "Payment-information-is-required",
        title: "Payment Issue",
        msg: "Please check your payment details and try again",
        type: "danger",
      });
    } else this.finalizeOrder(data);
  }

  finalizeOrder(data: any) {
    this.loading = "payment";
    this.orderService.alaCarteOrder(data).subscribe({
      next: (res) => {
        this.notify.toast({
          id: "order-success",
          title: "Order Placed",
          msg: "You have successfully submitted your order.",
          type: "success",
        });
        this.loading = null;
        this.router.navigate([`/payment/success`]);
      },
      error: (err) => {
        let error_info = err?.error?.errorMessage;
        let stripeErr = err.error?.error?.message;
        this.notify.toast({
          id: "cart-checkout-error",
          title: "Payment Issue",
          msg: `There was an issue processing your payment. Please check your information and try again. ${
            stripeErr || error_info
          }`,
          type: "danger",
        });

        this.loading = null;
      },
    });
  }

  orderPackage() {
    if (
      !this.user.person.address ||
      (!this.user.person.city && !this.user.person.zip)
    ) {
      return this.notify.toast({
        id: "invalid form information",
        title: "Address-Required ",
        msg: "A shipping address is required. Please update your shipping address to include, address, city, state and zip code.",
        type: "danger",
      });
    }
    this.loading = "payment";
    this.cartService.updateCartItems(this.packageItems).subscribe({
      next: (res) => {
        this.notify.toast({
          id: "order-success",
          title: "Order Placed",
          msg: "You have successfully submitted your order.",
          type: "success",
        });
        this.loading = null;
        this.router.navigate([`/payment/success`]);
      },
      error: (err) => {
        this.notify.toast({
          id: "cart-checkout-error",
          title: "Payment Issue",
          msg: "There was an issue preparing your photos for fulfillment. Please try again.",
          type: "warning",
        });

        this.loading = null;
      },
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
