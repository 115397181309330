// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  api: "https://hms-api.oranjcreative.com/api",
  host: "https://hms-api.oranjcreative.com",
  apiVersion: "v1",
  smApiVersion: "v2",
  paymentApi: "https://localhost:13334/api",
  paymentApiVersion: "v1",
  weatherToken: "6c8a6aa251f106b0675cbedef76e2651",
  weatherAPI: "https://api.openweathermap.org/data/2.5/weather?zip=",
  stripePublicKey: "pk_test_hJdJipQglLR3X6b6dlvGStCm",
  printService: "https://localhost:13334/api/v1",
  gApiUrl: "https://maps.googleapis.com/",
  gApiKey: "AIzaSyBgH85RTB452lZLOBgIFMH5S9RYI2hYxxg",
  gClientId:
    "502890695538-ta53onnjacc4t9a4lj9dcjbsncnjrghs.apps.googleusercontent.com",
  reservationFee: 2500,
  cancellationMult: 0.029,
  cancellationFee: 30,
  maintenanceMode: false,
};
