import { Component, OnDestroy } from "@angular/core";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-navigation-spinner",
  templateUrl: "./navigation-spinner.component.html",
  styleUrls: ["./navigation-spinner.component.scss"],
})
export class NavigationSpinnerComponent implements OnDestroy {
  loading = false;
  progress = 0;
  private progressInterval: any;
  private routerSubscription: Subscription;

  constructor(private router: Router) {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
        this.progress = 0;
        this.startProgress(event.url);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loading = false;
        this.stopProgress();
      }
    });
  }

  startProgress(url: string): void {
    let interval = 100;
    // if (url.includes("order-details") || url.includes("order-checkout"))
    //   interval = 175;
    this.progressInterval = setInterval(() => {
      if (this.progress < 100) {
        this.progress += 5; // Increment progress
      }
    }, interval); // Adjust speed as needed
  }

  stopProgress(): void {
    clearInterval(this.progressInterval);
    this.progress = 100; // Set progress to 100% on navigation end
    setTimeout(() => (this.progress = 0), 300); // Reset after short delay
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
    }
  }
}
