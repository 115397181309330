import { pick as _pick } from 'lodash';
import { Base } from './base';
import { Package } from './package';
import { Order } from './order';
import { Person } from './person';
import { Photo } from './photo';
import { Child } from './child';
import { Folder } from './folder';
import { GiftCard } from './gift-card';

export class Appointment extends Base {
  date: string;
  name: string;
  photos: Photo[] = [];
  children: Child[] = [];
  coverPhoto: string;
  person: Person;
  note: string;
  flagged_note: string;
  flagged: boolean;
  user_id: number;
  fulfilled: boolean;
  location_id: number;
  packagePhotoSize: any;
  packageSize: any;
  package: Package;
  order: Order;
  package_id: any;
  endtime: Date;
  begintime: Date;
  waiting_at: Date;
  in_studio_at: Date;
  completed_at: Date;
  status?: string;
  sm_coupon_code?: string;
  folder?: Folder;
  location?: any;
  coupon: GiftCard;
  gift_card: GiftCard;
  gift_card_applied: number;

  constructor(data?: Partial<Appointment>) {
    super(data);

    Object.assign(
      this,
      _pick(data, [
        "date",
        "name",
        "coverPhoto",
        "note",
        "flagged_note",
        "flagged",
        "user_id",
        "fulfilled",
        "packagePhotoSize",
        "packageSize",
        "package_id"
      ])
    );

    this.package = data?.package ? new Package(data.package) : null;
    this.order = data?.order ? new Order(data.order) : null;
    this.person = data?.person ? new Person(data.person) : null;
    this.photos = (data?.photos || []).map((photo) => new Photo(photo));
    this.folder = data?.folder ? new Folder(data.folder) : null;
  }
}
