import { Injectable } from "@angular/core";
import { HttpService } from "./api.service";
import { BehaviorSubject, Observable } from "rxjs";
import { Cart } from "@models/cart";
import { map } from "rxjs/operators";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class CartService {
  private cart = new BehaviorSubject<Cart[]>([]);
  cart$ = this.cart.asObservable();

  constructor(private http: HttpService, private user: UserService) {
    const userId = this.user.userId;
    this.fetchCartItems(userId);
  }

  /**
   * Get Cart Items that belong to a user
   * res [{}]
   */
  fetchCartItems(user_id: any) {
    this.http.post(`/cart/all/${user_id}`, {}).subscribe((res) => {
      const items = res.response.data;
      this.cart.next(items);
    });
  }

  createCartItem(cart: Cart[]): Observable<Cart[]> {
    return this.http.post(`/cart/create`, { cart }).pipe(
      map((res) => {
        this.fetchCartItems(this.user.userId);
        return res.data;
      })
    );
  }

  updateCart(cart: Cart): Observable<Cart> {
    return this.http.post(`/cart/update-single/${cart.id}`, { cart }).pipe(
      map((res) => {
        this.fetchCartItems(this.user.userId);
        return res.data;
      })
    );
  }

  updateCartItems(cartItems: Cart[]): Observable<Cart[]> {
    return this.http.post(`/cart/update-cart-items`, { cartItems }).pipe(
      map((res) => {
        this.fetchCartItems(this.user.userId);
        return res.data;
      })
    );
  }

  getApptCart(appointment_id: number): Observable<Cart[]> {
    const body = {
      get: ["*"],
      where: {
        appointment_id,
        package_order: true,
      },
    };

    return this.http.post(`/cart/get`, body).pipe(
      map((res) => {
        return res.data;
      })
    );
  }
}
