import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CreateAccountComponent} from './create-account.component';

const routes: Routes = [
  {
    path: 'create-account/:mode',
    data: { title: 'Holiday Magic - Create Account' },
    component: CreateAccountComponent
  },
  {
    path: 'create-account',
    data: { title: 'Holiday Magic - Create Account' },
    component: CreateAccountComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreateAccountRoutingModule {
}
