<div class="card mb-4 border-0 rounded-xl p-3">
  <h2 class="mb-3">Support</h2>
  <!-- Support Form -->
  <form [formGroup]="customerSupport">
    <app-input controlName="firstName"></app-input>

    <app-input controlName="lastName"></app-input>

    <app-phone-input controlName="phoneNumber" #phoneFocus></app-phone-input>

    <app-email-input controlName="email"></app-email-input>

    <app-select controlName="selectTopic"></app-select>

    <div class="mb-2">
      <textarea placeholder="Message" rows="7" class="form-control"></textarea>
    </div>

    <!-- file-upload.component.html -->

    <!-- file-upload.component.html -->

    <div
      class="file-upload-container d-flex justify-content-center align-items-center border rounded mb-2"
    >
      <input
        type="file"
        class="file-input"
        id="fileUpload"
        accept=".jpg,.png"
        multiple
        formControlName="message"
      />
      <label
        for="fileUpload"
        class="upload-label d-flex flex-column justify-content-between align-items-center w-100 p-5"
      >
        <h3 class="upload-text">Upload files</h3>
        <small class="text-muted">Accepts .jpg and .png</small>
      </label>
    </div>

    <button class="btn btn-secondary mb-3">Submit</button>
  </form>
</div>
