import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CartService } from "@services/cart.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-cart-icon",
  templateUrl: "./cart-icon.component.html",
  styleUrls: ["./cart-icon.component.scss"],
})
export class CartIconComponent implements OnInit {
  animationInterval: any;
  itemCount: number = 0;
  cartSubscription!: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cartService: CartService
  ) {}

  ngOnInit(): void {
    this.cartSubscription = this.cartService.cart$.subscribe((items) => {
      this.itemCount = items.length;
      if (this.itemCount > 0) {
        this.startAnimation();
      } else {
        this.stopAnimation();
      }
    });
  }

  startAnimation() {
    if (!this.animationInterval) {
      this.animationInterval = setInterval(() => {
        this.triggerAnimation();
      }, 5000);
    }
  }

  stopAnimation() {
    if (this.animationInterval) {
      clearInterval(this.animationInterval);
      this.animationInterval = null;
    }
  }

  triggerAnimation() {
    const cartIcon = document.querySelector(".cart-icon");
    if (cartIcon) {
      cartIcon.classList.add("rotate");
      setTimeout(() => cartIcon.classList.remove("rotate"), 1000);
    }
  }

  ngOnDestroy(): void {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
    this.stopAnimation();
  }
}
