import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Appointment } from '@models/appointment';
import { Child } from '@models/child';
import { DateTime } from 'luxon';

@Injectable()
export class BookingService {
  bookingForm: FormGroup;
  currentLocationTab = 0;

  constructor(
    private fb: FormBuilder,
  ) {
  }

  newChild(child?: Child) {
    const childGroup: FormGroup<any> = this.fb.group({
      name: [child?.name || '', Validators.required],
      age: [child?.age || null, Validators.required],
      gender: [child?.gender || null, Validators.required],
      trackBy: [new Date().getMilliseconds()]
    });

    if (child) {
      childGroup.addControl('id', this.fb.control(child.id, [Validators.required]));
    }

    return childGroup;
  }

  newBooking(appointment?: Partial<Appointment & { overrides: string[] }>) {
    this.bookingForm = this.fb.group({
      date: [
        appointment?.date ? DateTime.fromISO(appointment?.date).toFormat('yyyy-MM-dd HH:mm:ss').replace(' ', 'T') : null,
        Validators.required
      ],
      location: [appointment?.location || null, Validators.required]
    });

    if (appointment) {
      if (appointment.id) {
        this.bookingForm.addControl('id', this.fb.control(appointment.id, [Validators.required]));
      }
      if (appointment.overrides) {
        this.bookingForm.addControl('overrides', this.fb.array([]));
      }
    } else {
      this.bookingForm.addControl('pkg', this.fb.control(null, Validators.required));
      this.bookingForm.addControl('children', this.fb.array([this.newChild()]));
      this.bookingForm.addControl('stripe', this.fb.control(null));
      this.bookingForm.addControl('accepted', this.fb.control(null, Validators.requiredTrue));
      this.bookingForm.addControl('redeem_code', this.fb.control(''));
      this.bookingForm.addControl('coupon_code', this.fb.control(''));
      this.bookingForm.addControl('gift_card_code', this.fb.control(''));
      this.bookingForm.addControl('discount_type', this.fb.control(''));
    }
  }

  addChild() {
    (this.bookingForm.get('children') as FormArray)
      .push(this.newChild());
  }

  removeChild(i: number) {
    (this.bookingForm.get('children') as FormArray)
      .removeAt(i);
  }
}
