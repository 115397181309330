import { Injectable } from "@angular/core";
import { HttpService } from "./api.service";

import { Order } from "../models/order";
import { Cart } from "../models/cart";
import { BehaviorSubject, Observable } from "rxjs";
import { UserService } from "./user.service";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class FulfillmentService {
  private _cart = new BehaviorSubject<any[]>([]);
  cart$ = this._cart.asObservable();

  constructor(private http: HttpService, private user: UserService) {}

  /**
   * Get FulfillmentOrders
   * res [{}]
   */
  getFulfillmentCart(itemsPerPage?, offset?): Observable<Cart[]> {
    const params = JSON.parse(
      JSON.stringify({
        itemsPerPage,
        offset,
      })
    );

    return this.http
      .get(`/cart/all/fulfillment`, params)
      .pipe(map((res) => res.data as Cart[]));
  }

  /**
   * Get FulfillmentOrders
   * res [{}]
   */
  getFlaggedFulfillmentCart(itemsPerPage, offset): Promise<Cart[]> {
    const params = JSON.parse(
      JSON.stringify({
        itemsPerPage,
        offset,
      })
    );

    return this.http
      .get(`/cart/all/flagged`, params)
      .toPromise()
      .then((res) => {
        return res.data as Cart[];
      });
  }

  /**
   * Get FulfillmentOrders
   * res [{}]
   */
  getFulfillmentHistory(itemsPerPage, offset): Promise<Cart[]> {
    const params = JSON.parse(
      JSON.stringify({
        itemsPerPage,
        offset,
      })
    );

    return this.http
      .get(`/cart/history/fulfillment`, params)
      .toPromise()
      .then((res) => {
        return res.data as Cart[];
      });
  }

  searchFulfillmentCart(query: string, itemsPerPage, offset): Promise<Cart[]> {
    const params = JSON.parse(
      JSON.stringify({
        search: query,
        itemsPerPage,
        offset,
      })
    );

    return this.http
      .get(`/cart/search/fulfillment`, params)
      .toPromise()
      .then((res) => {
        return res.data as Cart[];
      });
  }

  /**
   * Get Orders
   * res [{}]
   */
  getActiveOrders(): Promise<Order[]> {
    return this.http
      .get(`/order/fulfillment`)
      .toPromise()
      .then((res) => {
        return res.data as Order[];
      });
  }

  getCartAppointments(user_id: any): Promise<Cart[]> {
    return this.http
      .post(`/cart/appointments/${user_id}`, {})
      .toPromise()
      .then((res) => {
        return res.data.response as Cart[];
      });
  }

  /**
   * Get Cart Items that belong to a user
   * res [{}]
   */
  setCart(user_id: any): Promise<any[]> {
    return this.http
      .post(`/cart/all/${user_id}`, {})
      .toPromise()
      .then((res) => {
        let cart = res.response.data as any;
        const carts = this._cart.getValue();
        carts.splice(0, carts.length);
        carts.push(cart);
        return cart;
      });
  }

  createCart(cart: any): Promise<Cart[]> {
    return this.http
      .post(`/cart/create`, { cart: cart })
      .toPromise()
      .then((res) => {
        this.setCart(this.user.userId);
        return res.data as Cart[];
      });
  }

  updateCart(cart: any): Promise<Cart[]> {
    return this.http
      .post(`/cart/update`, { cart: cart })
      .toPromise()
      .then((res) => {
        this.setCart(this.user.userId);
        return res.data as Cart[];
      });
  }

  searchCart(query: any): Promise<Cart[]> {
    return this.http
      .post(`/cart/search`, query)
      .toPromise()
      .then((res) => {
        return res.data.response as Cart[];
      });
  }

  removeCartItems(query: any): Promise<Cart[]> {
    //send back an array of the cart items to remove

    return this.http
      .post(`/cart/remove`, { cartIds: query })
      .toPromise()
      .then((res) => {
        this.setCart(this.user.userId);
        return res.data as Cart[];
      });
  }

  /*
   * Normalize method to update the cart model
   *
   * */
  updateCartItem(cart: Cart): Promise<Cart> {
    return this.http
      .put(`/cart/id/${cart.id}`, cart)
      .toPromise()
      .then((res) => {
        this.setCart(this.user.userId);
        return res.data as Cart;
      });
  }

  /**
   * Get Count for fulfillment Orders
   * res [{}]
   */
  getFulfillmentCount(): Promise<any> {
    return this.http
      .get(`/order/count`)
      .toPromise()
      .then((res) => {
        return res.count as any;
      });
  }

  /**
   * Get FulfillmentOrders
   * res [{}]
   */
  getFulfillmentOrders(itemsPerPage, offset): Promise<Order[]> {
    const params = JSON.parse(
      JSON.stringify({
        itemsPerPage,
        offset,
      })
    );

    return this.http
      .get(`/order/all/fulfillment`, params)
      .toPromise()
      .then((res) => {
        return res.data as Order[];
      });
  }

  searchFulfillment(query: string, itemsPerPage, offset): Promise<Order[]> {
    const params = JSON.parse(
      JSON.stringify({
        search: query,
        itemsPerPage,
        offset,
      })
    );

    return this.http
      .get(`/order/search`, params)
      .toPromise()
      .then((res) => {
        return res.data as Order[];
      });
  }

  /**
   * Get a Single Order
   * req: orderId:number
   * res {}
   */
  getOrder(orderId: number): Promise<Order> {
    return this.http
      .get(`/order/id/${orderId}`)
      .toPromise()
      .then((res) => {
        return res.data as Order;
      });
  }

  /**
   * Create a Single Order
   * req: order:Order
   * res {}
   */
  createOrder(order: Order): Promise<Order> {
    return this.http
      .post(`/order`, order)
      .toPromise()
      .then((res) => {
        return res.data as Order;
      });
  }

  /**
   * Update a Single Order
   * req: order:Order
   * res {}
   */
  updateOrder(order: Order): Promise<Order> {
    return this.http
      .put(`/order/id/${order.id}`, order)
      .toPromise()
      .then((res) => {
        return res.data as Order;
      });
  }

  /**
   * Get User Orders
   * res [{}]
   */
  getUserOrders(userId: number): Promise<Order[]> {
    return this.http
      .get(`/order/get-user-orders/${userId}`)
      .toPromise()
      .then((res) => {
        return res.data as Order[];
      });
  }

  getApptOrder(apptId: number): Promise<Order[]> {
    let data = {
      get: ["*"],
      where: {
        appointment_id: apptId,
        originating_order: true,
      },
    };
    return this.http
      .post(`/order/get`, data)
      .toPromise()
      .then((res) => {
        return res.data.data as Order[];
      });
  }

  /**
   * Get Appt Order
   * TODO: This is a duplicate of get Appt Order so as not to disrupt appt order service.
   * Will need to refactor after testing
   * res Order[]
   * */
  getSessionOrders(sessionId: number): Promise<Order[]> {
    let data = {
      get: ["*"],
      where: {
        appointment_id: sessionId,
        originating_order: true,
      },
    };
    return this.http
      .post(`/order/get`, data)
      .toPromise()
      .then((res) => {
        return res.data as Order[];
      });
  }

  sendNotification(userId: any): Promise<any> {
    return this.http
      .post(`/order/notify/${userId}`, {})
      .toPromise()
      .then((res) => {
        return res.data as any;
      });
  }
}
