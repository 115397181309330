import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'centsToDollars',
  pure: false
})
export class CentsToDollars implements PipeTransform {
  transform(value: number): any {
   return centsToDollars(value); 

  }
}

export function centsToDollars(cents: number) {
  // Convert cents to dollars by dividing by 100, and format to 2 decimal places
  return parseFloat((cents / 100).toFixed(2));
}